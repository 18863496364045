<template>
  <div class="max-w-xl px-6 mx-auto" ref="container">
    <div v-show="step == 'summary'">
      <h1 class="my-3 md:my-6 text-xl md:text-3xl uppercase leading-tight text-center text-gray-800 font-bold font-toriya">
        Récapitulatif de votre commande
      </h1>
      <div class="text-center" v-if="empty">
        <p class="text-gray-600 mb-12">
          Votre panier est vide.
        </p>
        <a href="/" class="bg-teal-600 hover:bg-teal-500 text-white font-bold p-4 rounded outline-none focus:outline-none">
          Retour à l'accueil
        </a>
      </div>
      <div v-else>
        <div class="relative rounded border border-gray-400 bg-white p-4 md:p-8 my-3 shadow-md" v-for="item in order.items">
          <div class="divide-y divide-gray-300">
            <div class="flex mb-2">
              <div class="flex-grow mb-2">
                <div class="font-bold text-xl text-gray-900 font-toriya mb-2 uppercase">
                  {{ item.product.title }}
                </div>
                <div class="text-xs text-gray-500 uppercase">
                  {{ item.product.ingredients.join(' • ') }}
                </div>
              </div>
              <div class="text-gray-600 text-xl pl-6 font-toriya">
                {{ item.amount | amount }}
              </div>
            </div>
            <div class="flex text-sm py-2" v-for="extra in item.extras">
              <div class="flex-grow">
                <span class="text-gray-800">
                  {{ extra.product.title }}
                </span>
                <span class="text-gray-600 ml-1">
                  &times;
                  {{ extra.quantity }}
                </span>
                <div class="text-xs text-gray-500 uppercase">
                  {{ extra.product.ingredients.join(' • ') }}
                </div>
              </div>
              <div class="text-gray-700 pl-6">
                {{ extra.amount | amount }}
              </div>
            </div>
            <div class="flex pt-3">
              <div class="flex-grow text-gray-600">
                Sous-total
              </div>
              <div class="font-toriya">
                {{ item.total_amount | amount }}
              </div>
            </div>
          </div>
          <div class="mt-3">
            <a class="border border-red-100 block w-full text-center bg-red-100 bg-opacity-75 hover:bg-opacity-100 text-opacity-50 hover:text-opacity-100 text-red-700 py-2 text-sm cursor-pointer rounded" v-show="!item.removable" @click="$set(item, 'removable', true)">
              Retirer du panier
            </a>
            <div class="grid md:grid-cols-2 gap-3 grid-flow-row-dense" v-show="item.removable">
              <a class="border border-red-700 bg-red-600 text-center text-white py-2 text-sm cursor-pointer rounded" @click="removeItem(item)">
                Confirmer le retrait du panier ?
              </a>
              <a class="bg-gray-200 text-gray-700 py-2 text-center text-sm cursor-pointer rounded" @click="item.removable = false">
                Annuler
              </a>
            </div>
          </div>
        </div>
        <div class="rounded border border-gray-400 bg-white p-4 divide-y divide-gray-300 shadow-md my-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <div class="mr-2">
                <svg width="48px" height="48px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#444" d="M253.8 19.46h-1.1c-1.8.1-3.5.34-5.1.85-4.2 1.3-7.4 3.84-9.6 9.49l-14.5 39.6 33.8 12.74 16.4-38.93c2.2-5.65 1.6-9.64-.6-13.3-2.2-3.8-6.3-7.04-11.2-8.87-2.6-1-5.4-1.53-8.1-1.59zM217.4 86.2 82.21 455.4l15.84 5.9L250.4 98.63zm189.8 11.6c-4.3.2-8.1 1.85-11.8 6.7l-24.5 34.6 29.3 20.8 26.1-33.4c3.7-4.8 4.1-8.9 3-12.9-1.2-4.3-4.2-8.4-8.5-11.4-3.7-2.67-8-4.21-11.9-4.4h-1.7zm-46.7 55.9L132.1 476l13.7 9.7 243.3-311.6z"/>
                </svg>
              </div>
              <div class="flex-grow text-gray-600">
                Souhaitez-vous des baguettes&nbsp;?
              </div>
            </div>
            <div class="bg-gray-200 text-sm text-gray-600 leading-none border-2 border-gray-200 rounded-full flex">
              <button
                class="flex-grow focus:outline-none rounded-r-full px-6 py-4"
                :class="{'bg-white rounded-full text-yellow-700 font-bold': model.chopsticks}"
                @click="model.chopsticks = true">
                <span>Oui</span>
              </button>
              <button
                class="flex-grow focus:outline-none rounded-l-full px-6 py-4"
                :class="{'bg-white rounded-full text-yellow-700 font-bold': !model.chopsticks}"
                @click="model.chopsticks = false">
                <span>Non</span>
              </button>
            </div>
          </div>
        </div>
        <div class="rounded border border-gray-400 bg-white p-4 md:p-8 divide-y divide-gray-300 shadow-md">
          <div class="flex pb-3">
            <div class="flex-grow text-gray-600">
              Total TTC
            </div>
            <div class="text-right font-bold font-toriya">
              {{ order.total_amount | amount }}
            </div>
          </div>
          <div class="flex py-2 text-sm" v-for="(amount, vat) in order.vat_parts">
            <div class="flex-grow text-gray-500">
              TVA {{ vat | percentage }}
            </div>
            <div class="text-right text-gray-600 font-toriya">
              {{ amount | amount }}
            </div>
          </div>
          <div class="flex pt-3 text-sm md:text-base">
            <div class="flex-grow text-gray-600">
              Restaurant sélectionné
            </div>
            <div class="text-right uppercase font-bold font-toriya">
              Tori Ya {{ currentSite.title }}
            </div>
          </div>
        </div>
        <div class="mt-6 flex flex-col md:flex-row-reverse items-center justify-between">
          <div class="mb-6 md:mb-0 w-full md:w-auto">
            <a @click="step = 'pickup'" class="cursor-pointer block w-full text-center bg-verdigris-500 hover:bg-verdigris-700 text-white font-bold p-4 rounded outline-none focus:outline-none">
              <i class="fas fa-check mr-1"></i>
              Valider la commande
            </a>
          </div>
          <div class="mt-6 md:mt-0">
            <a class="cursor-pointer text-gray-900" href="/#la-carte">
              <i class="fas fa-arrow-left mr-2"></i>
              Ajouter d'autres produits
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-show="step == 'pickup'">
      <h1 class="my-3 md:my-6 text-xl md:text-3xl uppercase leading-tight text-center text-gray-800 font-toriya font-bold">
        Retrait de votre commande
      </h1>
      <div class="rounded border border-gray-400 bg-white p-4 lg:p-6 my-3">
        <p class="text-gray-700 flex items-center font-toriya text-xl">
          <i class="far fa-clock fa-3x mr-3 text-gray-500"></i>
          À quelle heure souhaitez-vous récupérer votre commande au restaurant&nbsp;?
        </p>
        <div class="relative inline-flex w-full mt-6">
          <svg class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232">
            <path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fill-rule="nonzero"/>
          </svg>
          <select ref="scheduled_at" class="w-full border border-gray-300 rounded-full text-gray-700 h-10 px-5 bg-white hover:border-gray-400 focus:outline-none appearance-none cursor-pointer" v-model="model.scheduled_at">
            <optgroup v-for="pickup in pickups" :label="pickup.day" v-if="pickup.times.length > 0">
              <option v-for="time in pickup.times" :value="time">
                {{ timeOption(time) }}
              </option>
            </optgroup>
          </select>
        </div>
        <div class="border relative rounded p-1 mt-8">
          <div class="-mt-4 absolute tracking-wider px-1">
            <p>
              <label for="first_name" class="bg-white text-gray-600 px-2 font-toriya">
                Votre nom
                <span class="text-red-700">*</span>
              </label>
            </p>
          </div>
          <p>
            <input id="first_name" autocomplete="false" tabindex="0" type="text" class="p-4 text-gray-900 outline-none block h-full w-full" v-model.trim="model.first_name">
          </p>
        </div>
      </div>
      <div class="rounded border border-gray-400 bg-white p-4 lg:p-6 my-3">
        <p class="text-gray-700 flex items-center font-toriya text-xl">
          <i class="fas fa-sms fa-3x mr-3 text-gray-500"></i>
          Recevoir un SMS lorsque votre commande est prête&nbsp;?
        </p>
        <div class="border relative rounded p-1 mt-6">
          <div class="-mt-4 absolute tracking-wider px-1">
            <p>
              <label for="phone" class="bg-white text-gray-600 px-2 font-toriya">
                Votre téléphone mobile
              </label>
            </p>
          </div>
          <p>
            <input id="phone" tabindex="0" type="tel" class="p-4 text-gray-900 outline-none block h-full w-full" v-model.trim="model.phone" v-mask="'0# ## ## ## ##'">
          </p>
        </div>
      </div>
      <div class="mt-6 flex flex-col md:flex-row-reverse items-center justify-between">
        <button
          @click="submitOrder()"
          :disabled="busy || !valid"
          :class="(busy || !valid) ? 'bg-opacity-50 cursor-not-allowed' : 'hover:bg-verdigris-700'"
          class="mb-6 md:mb-0 w-full md:w-auto cursor-pointer text-center bg-verdigris-500 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none">
          <i class="fas fa-fw mr-2" :class="busy ? 'fa-spinner fa-pulse' : 'fa-credit-card'"></i>
          Payer la commande
        </button>
        <div class="text-center md:text-left">
          <div class="font-light text-sm mb-1">
            <i class="fa fa-lock mr-1 text-gray-700"></i>
            <span class="text-gray-700">Paiement en ligne sécurisé</span>
          </div>
          <div>
            <i class="text-blue-700 fab fa-cc-visa fa-2x mr-2"></i>
            <i class="fab fa-cc-mastercard fa-2x mx-2"></i>
            <i class="text-teal-700 fab fa-cc-amex fa-2x ml-2"></i>
          </div>
        </div>
      </div>
      <form :action="transaction.url" method="post" class="hidden" accept-charset="UTF-8" ref="paymentForm" v-if="transaction">
        <input type="hidden" :name="name" :value="value" v-for="(value, name) in transaction.params">
      </form>
    </div>
  </div>
</template>

<script>
  import { format, add, set, isBefore, isToday, parseISO, formatISO, getDay } from 'date-fns'
  import axios from 'axios'
  import { mask } from 'vue-the-mask'
  import Amount from '../../../filters/Amount'
  import Time from '../../../filters/Time'
  import Percentage from '../../../filters/Percentage'
  import { OrderBus } from '../../../packs/toriya/front'
  export default {
    directives: {
      mask
    },
    filters: {
      Amount,
      Time,
      Percentage
    },
    props: {
      sites: Array
    },
    data() {
      return {
        step: 'summary',
        order: {
          items: []
        },
        transaction: null,
        model: {
          first_name: null,
          scheduled_at: null
        },
        busy: false
      }
    },
    mounted() {
      OrderBus.$on('order:updated', (order) => {
        this.order = order
        this.model = {
          first_name: order.first_name,
          scheduled_at: this.pickups.flatMap((pickup) => pickup.times)[0],
          chopsticks: order.chopsticks
        }
      })
    },
    computed: {
      valid() {
        return (
          this.model.first_name != null &&
          this.model.first_name != '' &&
          this.model.scheduled_at != null &&
          (
            this.model.phone == null ||
            this.model.phone == '' ||
            this.model.phone.replace(/\s/g, '').match(/0[6,7]\d{8}/) != null
          )
        )
      },
      empty() {
        return this.order.items.length == 0
      },
      pickups() {
        const pickups = [{
          day: "Aujourd'hui", times: [],
        }, {
          day: "Demain", times: []
        }]
        let times = [
          { hours: 11, minutes: 30 },
          { hours: 11, minutes: 45 },
          { hours: 12, minutes: 0 },
          { hours: 12, minutes: 15 },
          { hours: 12, minutes: 30 },
          { hours: 12, minutes: 45 },
          { hours: 13, minutes: 0 },
          { hours: 13, minutes: 15 },
          { hours: 13, minutes: 30 },
          { hours: 13, minutes: 45 },
          { hours: 14, minutes: 0 },
          { hours: 14, minutes: 15 },
          { hours: 14, minutes: 30 },
          { hours: 14, minutes: 45 },
          { hours: 15, minutes: 0 },
          { hours: 15, minutes: 15 },
          { hours: 15, minutes: 30 },
          { hours: 15, minutes: 45 },
          { hours: 16, minutes: 0 },
          { hours: 16, minutes: 15 },
          { hours: 16, minutes: 30 },
          { hours: 16, minutes: 45 },
          { hours: 17, minutes: 0 },
          { hours: 17, minutes: 15 },
          { hours: 17, minutes: 30 },
          { hours: 17, minutes: 45 },
          { hours: 18, minutes: 0 },
          { hours: 18, minutes: 15 },
          { hours: 18, minutes: 30 },
          { hours: 18, minutes: 45 },
          { hours: 19, minutes: 0 },
          { hours: 19, minutes: 15 },
          { hours: 19, minutes: 30 },
          { hours: 19, minutes: 45 },
          { hours: 20, minutes: 0 },
          { hours: 20, minutes: 15 },
          { hours: 20, minutes: 30 },
          { hours: 20, minutes: 45 },
          { hours: 21, minutes: 0 },
          { hours: 21, minutes: 15 },
          { hours: 21, minutes: 30 }
        ]
        const nowWithOffest = add(new Date(), { minutes: 25 })
        for (let dayOffset = 0; dayOffset < 2; dayOffset++) {
          let date = add(new Date(), { days: dayOffset })
          let day = getDay(date)
          let fmt
          times.forEach((time) => {
            date = set(date, { hours: time.hours, minutes: time.minutes, seconds: 0, milliseconds: 0 })
            fmt = format(date, 'MM-dd')
            if (isBefore(nowWithOffest, date) && (fmt != '12-31' || (fmt == '12-31' && time.hours >= 11 && time.hours <= 15)) && (fmt != '12-24' || (fmt == '12-24' && time.hours >= 11 && time.hours <= 14)) && fmt != '12-25') {
              pickups[dayOffset]['times'].push(formatISO(date))
            }
          })
        }
        return pickups
      },
      currentSite() {
        return this.sites.find((site) => site.id == this.order.site_id)
      }
    },
    methods: {
      timeOption(time) {
        let date = parseISO(time)
        if (isToday(date)) {
          return `Aujourd'hui entre ${format(date, "HH'h'mm")} et ${format(add(date, { minutes: 15 }), "HH'h'mm")}`
        } else {
          return `Demain entre ${format(date, "HH'h'mm")} et ${format(add(date, { minutes: 15 }), "HH'h'mm")}`
        }
      },
      removeItem(item) {
        axios.delete(item.url).then((response) => {
          OrderBus.$emit('order:updated', response.data)
        })
      },
      submitOrder() {
        if (this.busy) return
        this.busy = true
        axios.patch(
          this.order.url, {
            order: this.model
          }
        ).then((response) => {
          axios.post(
            this.order.transaction_url
          ).then((response) => {
            this.transaction = response.data
            this.$nextTick(() => {
              this.$refs.paymentForm.submit()
            })
          })
        }).catch((error) => {
          this.busy = false
          console.error(error)
        })
      }
    },
    watch: {
      step() {
        window.scrollTo(0, 0)
      },
      'model.scheduled_at': function(value) {
        let text = ''
        if (value == null) {
          text = "Dès que possible"
        } else {
          let from = parseISO(value)
          let to = add(from, { minutes: 15 })
          if (isToday(from)) {
            text = `Aujourd'hui entre ${format(from, "HH'h'mm")} et ${format(to, "HH'h'mm")}`
          } else {
            text = `Demain entre ${format(from, "HH'h'mm")} et ${format(to, "HH'h'mm")}`
          }
        }
        this.$nextTick(() => {
          this.$refs.scheduled_at.selectedOptions[0].text = text
        })
      }
    }
  }
</script>
